#form-feedback {
    display: none;
    padding: 0;
    background: none;

    .form-def {
        margin: 0;

        &__wrapper {
            padding: rem(16);
            border-radius: $radius-10;
            @media (min-width: 768px) {
                padding: rem(48) rem(36);
            }
        }

        &__inner {
            grid-gap: rem(10);
            margin: rem(12) 0 0;
            @media (min-width: 576px) {
                grid-gap: rem(20);
                grid-template-columns: repeat(2, 1fr);
            }
            @media (min-width: 992px) {
                grid-template-columns: repeat(3, 1fr);
                margin: rem(20) 0 0;
            }
        }

        &__title {
            margin: 0 0 rem(12);
            padding-right: rem(40);
            @media (min-width: 768px) {
                padding-right: rem(60);
                font-size: rem(48);
            }
        }

        &__field {
            &.feedback-text {
                @media (min-width: 576px) {
                    grid-column: 1 / -1;
                }
            }
            &_btn {
                order: 1;
                width: 100%;
                margin: rem(4) 0 0;
                @media (min-width: 576px) {
                    grid-column: 1 / -1;
                    margin: 0;
                }
                button {
                    max-width: rem(226);
                }
            }
        }

        &__privacy {
            @media (min-width: 576px) {
                grid-column: 1 / -1;
            }
            @media (min-width: 1200px) {
                max-width: 76%;
            }
        }
    }

    .feedback-filial__regions .form-def__input._open ~ .form-def__select-list {
        max-height: rem(310);
        overflow: auto;
    }

    .fancybox-close-small {
        top: rem(14);
        right: rem(14);
        width: rem(40);
        height: rem(40);
        margin-right: $layout-gap-xs;
        background-color: $white;
        border-radius: 50%;
        transition: .2s;
        &:hover {
            background-color: $blue-light;
        }
        @media (min-width: 768px) {
            top: rem(18);
            right: rem(18);
            width: rem(60);
            height: rem(60);
            margin-right: $layout-gap-md;
        }
        @media (min-width: 1200px) {
            top: rem(31);
            right: rem(36);
        }
        svg {
            stroke: $grey-600;
            filter: none;
        }
    }
}