// миксин для создания масштабируемых элементов, чтобы поддерживать соотношение. @include responsive-ratio(16,9)
@mixin responsive-ratio($x, $y, $pseudo: false) {
  $padding: unquote(($y / $x) * 100 + "%");
  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}

//css треугольники
@mixin css-triangle(
  $color,
  $direction,
  $size: 6px,
  $position: absolute,
  $round: false
) {
  @include pseudo($pos: $position);
  width: 0;
  height: 0;
  @if $round {
    border-radius: 3px;
  }
  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round($size / 2.5);
  } @else if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round($size / 2.5);
  } @else if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}

//transition
@mixin tr($properties...) {
  $declarations: ();

  @each $declaration in $properties {
      $duration: .4s;
      $delay: false;
      $timing-function: ease;
      $property: all;

      @if type-of($declaration) == 'map' {
          $duration: if(map_get($declaration, 'duration'), #{map_get($declaration, 'duration')}, $duration);
          $delay: if(map_get($declaration, 'delay'), #{map_get($declaration, 'delay')}, $delay);
          $timing-function: if(map_get($declaration, 'timing-function'), #{map_get($declaration, 'timing-function')}, $timing-function);
          $property: if(map_get($declaration, 'property'), #{map_get($declaration, 'property')}, $property);
      } @else {
          $property: $declaration;
      }

      $delayValue: if($delay, ' ' + $delay, '');
      $declarations: append($declarations, #{$property + ' ' + $duration + $delayValue + ' ' + $timing-function}, comma);
  }

  transition: $declarations;
}

// grey block
@mixin striped($block: '') {
  @if $block {
    @at-root .striped &:nth-child(odd) #{$block} {
      @content;
    }
  }
}

@mixin striped-reverse($block: '') {
    @if $block {
      @at-root .striped-reverse &:nth-child(even) #{$block} {
        @content;
      }
    }
  }