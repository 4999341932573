.form-def {
    margin: rem(24) 0 0;
    @media (min-width: 768px) {
        margin: rem(40) 0 0;
    }
    @media (min-width: 1200px) {
        margin: rem(80) 0 0;
    }

    &__wrapper {
        padding: rem(36) rem(16);
        border-radius: $radius-4;
        background-color: $bg-grey;
        @media (min-width: 768px) {
            padding: rem(36);
            border-radius: $radius-10;
        }
    }

    &__title {
        display: block;
        margin: 0 0 rem(12);
        font-size: rem(24);
        font-weight: 700;
        line-height: 1.15;
        @media (min-width: 768px) {
            margin: 0 0 rem(20);
            font-size: rem(32);
            line-height: 1.25;
        }
    }

    &__description {
        margin: rem(12) 0;
        font-size: rem(14);
        font-weight: 600;
        line-height: 1.44;
        @media (min-width: 768px) {
            margin: rem(16) 0;
            font-size: rem(16);
        }
        @media (min-width: 1200px) {
            max-width: 76%;
            line-height: 1.4;
        }
    }


    &__inner {
        display: grid;
        width: 100%;
        margin: rem(22) 0 0;
        grid-gap: rem(12);
        @media (min-width: 768px) {
            margin: rem(20) 0 0;
            grid-gap: rem(20) rem(8);
            grid-template-columns: 1fr 1fr;
        }
        @media (min-width: 992px) {
            grid-template-columns: 1fr 1fr 1fr auto;
        }
        @media (min-width: 1200px) {
            flex: 4;
            grid-gap: rem(20);
        }
    }


    &__field {
        position: relative;
        width: 100%;
        min-width: 0;
        &_btn {
            margin-top: rem(6);
        }
        @media (min-width: 768px) {
            &_btn {
                width: 100%;
                margin: 0;
            }
        }
        @media (min-width: 992px) {
            &_btn {
                width: rem(252);
            }
        }
    }

    &__input {
        display: block;
        width: 100%;
        margin: 0;
        padding: rem(14) rem(18);
        font-size: rem(16);
        line-height: 1.15;
        text-transform: none;
        border: rem(1) solid $grey-500;
        border-radius: rem(4);
        transition: 0.2s;

        @media (min-width: 768px) {
            padding: rem(18) rem(24);
            font-size: rem(18);
            line-height: 1.2;
        }
        &_textarea {
            height: rem(180);
            resize: vertical;
        }
        &:focus {
            border-color: $base;
            outline: none;
        }
        &.error,
        &.error:focus {
            border-color: $red;
        }
        &::placeholder {
            color: $grey-600;
        }
    }

    &__select {
        position: relative;
        display: block;
        &-input {
            cursor: default;
            padding-right: rem(40);
            color: $grey-600;
            background: $white
                url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNNSA3LjVMMTAgMTIuNUwxNSA3LjUiIHN0cm9rZT0iIzlGQUVCRiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==")
                no-repeat;
            appearance: none;
            background-position: right rem(16) center;
            resize: none;
            @media (min-width: 768px) {
                padding-right: rem(40);
            }
            &._open {
                border-radius: rem(4) rem(4) 0 0;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiB0cmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAtMSAwIDIwKSIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTUgMTIuNUwxMCA3LjVMMTUgMTIuNSIgc3Ryb2tlPSIjRkUzMzRBIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
            }
            &._checked {
                overflow: hidden;
                color: $base;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &:focus {
                border-color: $grey-500;
            }
            input {
                display: none;
                width: 100%;
                border: none;
            }
            &._other input {
                display: block;
                outline: none;
            }
        }
        &-placeholder {
            pointer-events: none;
        }
        &-input._other &-placeholder {
            display: none;
        }
        &.error &-input {
            border-color: $red;
        }
        &-list {
            overflow: hidden;
            z-index: 1;
            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
            display: flex;
            max-height: 0;
            margin-top: rem(-1);
            border: 0 solid $grey-500;
            border-radius: 0 0 $radius-4 $radius-4;
            background-color: $white;
            &_select {
                flex-direction: column;
            }
            &_choose {
                text-align: center;
                flex-wrap: wrap;
            }
        }
        &-input {
            &:focus {
            }
        }
        &-input._open ~ &-list {
            max-height: fit-content;
            padding: rem(12) 0;
            border-width: rem(1);
        }
        &-option {
            position: relative;
            padding: rem(8) rem(20);
            transition: 0.2s;
            flex: 1;
            &:hover {
                color: $red;
            }
            &_other {
                text-align: left;
            }
            & input {
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }
    }

    label.error {
        display: none !important;
    }

    &__required {
        display: block;
        margin: rem(2) 0 0;
        font-size: rem(12);
        line-height: 1.3;
        color: $red;
        @media (min-width: 576px) {
            grid-column: 1 / -1;
        }
        @media (min-width: 768px) {
            font-size: rem(16);
        }
    }

    &__button {
        width: 100%;
        max-width: rem(162);
        transition: 0.2s;
        @media (min-width: 768px) {
            max-width: unset;
        }
    }
    &__button:hover {
        opacity: 0.8;
    }

    &__privacy {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: rem(8) 0 0;
        @media (min-width: 768px) {
            margin: 0;
            grid-column: 1 / -1;
        }
        @media (min-width: 1200px) {
            grid-column: 1 / -2;
        }

        &-checkbox,
        &-ico {
            width: rem(20);
            height: rem(20);
        }
        &-checkbox {
            z-index: 1;
            position: absolute;
            display: block;
            opacity: 0;
        }
        &-ico {
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: rem(1) rem(14) 0 0;
            border: rem(1) solid $grey-500;
            border-radius: rem(4);
            transition: $tr-duration;
            flex: none;
            background-color: $white;
            svg {
                width: rem(12);
                opacity: 0;
                transition: $tr-duration;
            }
        }
        &-checkbox:checked ~ &-ico {
            border-color: transparent;
            background-color: $base;
            svg {
                opacity: 1;
            }
        }

        &-text {
            font-size: rem(10);
            line-height: 1.4;
            color: inherit;
            @media (min-width: 768px) {
                font-size: rem(12);
                line-height: 1.25;
            }
            & a {
                text-decoration: underline;
                color: inherit;
            }
        }
    }
}

.section {
    @include striped() {
        .form-def__wrapper {
            background-color: $white;
        }
    }
}


.fancybox__container .form-def {
    margin: 0;
}